<template>
  <tr
    @click="detail(item.id)"
  >
    <td
      v-if="selectable"
      width="30"
    >
      <v-checkbox
        class="mt-0 pt-0"
        dense
        v-model="localSelected"
        @click.passive.stop=""
        hide-details
      />
    </td>
    <td>{{item.id}}</td>
    <td>{{item.declarant_full_name}}</td>
    <td>{{timestampToDate(item.created_at)}}</td>
    <td>{{item.object_address}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'

export default {
  mixins: [formatters, listItem],
  name: 'ListItem'
}
</script>

<style scoped>

</style>
