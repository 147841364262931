<template>
  <div>
    <BaseList
      v-model="selectedItems"
      :controller="'felling-ticket'"
      :query="{filter}"
      :headers="headers"
      :show-select="true"
      :is-filter="true"
      :is-report="!!selectedItems.length"
      @report="handleReport"
      @filter="handleFilter"
    >
      <template #item="{item, isSelected}">
        <ListItem
          :item="item"
          :is-selected="isSelected"
          selectable
          @input="handleSelected($event)"
        />
      </template>
    </BaseList>
    <BaseReportPanel
      v-model="dialog"
      :selected-items="selectedItems"
      report-type="fellingTicket"
    />
    <BaseDialog
      label="Фильтр"
      :max-width="800"
      hideScroll
      v-model="isDialogFilter"
      is-cross-close
    >
      <template #content>
        <BaseFilter
          @handleSearch="handleSearch"
          @clearFilter="handleClear"
        >
          <template #filters>
            <FilterFellingTicket
              @change="changeField"
              @emptyField="emptyField"
            />
          </template>
        </BaseFilter>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import FilterFellingTicket from './list/FilterFellingTicket'
import ListItem from './list/ListItem'
import BaseDialog from '@/components/base/BaseDialog'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'
import filter from '@/components/mixins/filter'
import BaseFilter from '@/components/base/BaseFilter'
import report from '@/components/mixins/report'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import BaseReportPanel from '@/components/base/BaseReportPanel'

export default {
  mixins: [filter, report, tableSelectedItems],
  name: 'List',
  components: { BaseList, ListItem, FilterFellingTicket, BaseFilter, BaseDialog, BaseReportPanel },
  data () {
    return {
      headers: headers,
      filter: {}
    }
  },
  methods: {
    findSelectedItem: item => data => item.id === data.id,
  }
}
</script>

<style scoped>

</style>
